import { ViewHook } from 'phoenix_live_view';
import { FeaturesMode } from 'domains/features/types';
import { AppProps } from 'App';

type ModelEditorHook = ViewHook & {
  props: AppProps;
  reactCtx: {
    unmount: (el: Element) => void;
    render: (opts: AppProps) => void;
  };
  loadApp: () => Promise<void>;
  render: () => void;
  onShareModel: () => void;
  onCreateAccount: (modelJson: string) => void;
  onLogin: (modelJson: string) => void;
  newModel: (modelJson: string) => void;
  copyModel: (modelJson: string) => void;
  onUpgradeAccount: (modelJson: string) => void;
};

export const ModelEditor = {
  mounted(this: ModelEditorHook) {
    this.loadApp().catch(e => {
      console.error(e);
      this.pushEventTo(this.el, 'load_failed', {});
    });
  },

  destroyed(this: ModelEditorHook) {
    if (!this.reactCtx.unmount) {
      console.error('ModelEditor unmount not set');
      return;
    }

    this.reactCtx.unmount(this.el);
  },

  async loadApp(this: ModelEditorHook) {
    const { mount } = await import('../main');

    this.reactCtx = mount(this.el.id);

    if (!this.el.dataset.mode) {
      throw new Error('Missing initial mode for editor');
    }

    this.props = {
      mode: this.el.dataset.mode as FeaturesMode,
      currentUserisOwner: this.el.dataset.isOwner != undefined,
      isAuthenticated: this.el.dataset.isAuthenticated != undefined,
      isSubscriber: this.el.dataset.isSubscriber != undefined,
      subscriptionsEnabled: this.el.dataset.subscriptionsEnabled != undefined,
      publishingEnabled: this.el.dataset.publishingEnabled != undefined,
      actions: {
        onShareModel: this.onShareModel.bind(this),
        onCreateAccount: this.onCreateAccount.bind(this),
        onLogin: this.onLogin.bind(this),
        newModel: this.newModel.bind(this),
        copyModel: this.copyModel.bind(this),
        onUpgradeAccount: this.onUpgradeAccount.bind(this),
      },
    };

    this.render();
  },

  updated(this: ModelEditorHook) {
    this.props = {
      ...this.props,
      mode: this.el.dataset.mode as FeaturesMode,
      currentUserisOwner: this.el.dataset.isOwner != undefined,
      isAuthenticated: Boolean(this.el.dataset.isAuthenticated),
      isSubscriber: Boolean(this.el.dataset.isSubscriber),
      subscriptionsEnabled: Boolean(this.el.dataset.subscriptionsEnabled),
      publishingEnabled: Boolean(this.el.dataset.publishingEnabled),
    };

    this.render();
  },

  render(this: ModelEditorHook) {
    this.reactCtx.render(this.props);
  },

  onShareModel(this: ModelEditorHook) {
    this.pushEvent('publish', {});
  },

  onCreateAccount(this: ModelEditorHook, modelJson: string) {
    this.pushEvent('create_account', { modelJson });
  },

  onLogin(this: ModelEditorHook, modelJson: string) {
    this.pushEvent('login', { modelJson });
  },

  newModel(this: ModelEditorHook, modelJson: string) {
    this.pushEvent('new_model', { modelJson });
  },

  copyModel(this: ModelEditorHook, modelJson: string) {
    this.pushEvent('copy_model', { modelJson });
  },

  onUpgradeAccount(this: ModelEditorHook, modelJson: string) {
    this.pushEvent('upgrade_account', { modelJson });
  },
};
