import { ViewHook } from 'phoenix_live_view';

type CopyToClipboardHook = ViewHook & {
  copied: boolean;
  copyToClipboard: () => void;
  onCopySucceeded: () => void;
};

/**
 * This hook is used to copy text to the clipboard.
 */
export const CopyToClipboard = {
  mounted(this: CopyToClipboardHook) {
    if (!this.el.dataset.clipboardText) {
      console.warn('Missing clipboard text for copy to clipboard hook');
    }

    this.el.addEventListener('click', this.copyToClipboard.bind(this));
  },

  copyToClipboard(this: CopyToClipboardHook) {
    if (!this.copied && this.el.dataset.clipboardText) {
      navigator.clipboard.writeText(this.el.dataset.clipboardText || '').then(() => {
        this.onCopySucceeded();
      });
    }
  },

  onCopySucceeded(this: CopyToClipboardHook) {
    this.copied = true;
    const beforeText = this.el.textContent;
    this.el.textContent = this.el.dataset.copiedText || 'Copied!';
    this.el.setAttribute('disabled', 'disabled');

    setTimeout(() => {
      this.el.textContent = beforeText;
      this.copied = false;
      this.el.removeAttribute('disabled');
    }, 3000);
  },
};
